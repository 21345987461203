import { Box } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { PiFilePdfDuotone } from 'react-icons/pi';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { Button, Dropdown, Input, Modal, PDFDisplay } from '..';
import { ButtonTransparent, FooterTwo, FormModal } from '../Modal/styles';
import { useAuth } from '../../contexts/authContext';
import { getChipByCompany } from '../../services/Chip';
import { Chip } from '../../services/Chip/types';
import { getComponentByCompany } from '../../services/Components';
import { Component } from '../../services/Components/types';
import { getEquipmentByCompany } from '../../services/Equipment';
import { Equipment } from '../../services/Equipment/types';
import { getMaintenanceTypes } from '../../services/Maintenance';
import { MaintenanceType } from '../../services/Maintenance/types';
import colors from '../../styles/colors';
import { Content, ContentExcel, ContentPdf } from './styles';

interface OpenMaintenancesReportModalProps {
  linkMaintenanceId: string;
  setShowReportMaintenance: (value: boolean) => void;
}
type ModalPage =
  | 'reportType'
  | 'filters'
  | 'fileType'
  | 'historyFileType'
  | 'assesmentFileType'
  | 'displayPdf'
  | 'mtbfFileType';

export default function OpenMaintenancesReportModal({
  linkMaintenanceId,
  setShowReportMaintenance,
}: OpenMaintenancesReportModalProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const storageToken = localStorage.getItem('@App:token');

  const [page, setPage] = useState<ModalPage>('reportType');
  const [rowData, setRowData] = useState('');
  const [urlQuery, setUrlQuery] = useState('');

  const [component, setComponent] = useState('');
  const [equipment, setEquipment] = useState('');
  const [type, setType] = useState('');
  const [chip, setChip] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [componentsByCompany, setComponentsByCompany] = useState<Component[]>(
    [],
  );
  const [equipmentsCompany, setEquipmentsCompany] = useState<Equipment[]>([]);
  const [typeMaintenance, setTypeMaintenance] = useState<MaintenanceType[]>([]);
  const [chipByCompany, setChipByCompany] = useState<Chip[]>([]);

  useEffect(() => {
    const getComponentByCompanyData = async (
      id: string,
      signal: AbortSignal,
    ) => {
      const response = await getComponentByCompany({ params: { id }, signal });
      if (response.error === 0) {
        setComponentsByCompany(response.data);
      }
    };

    const getEquipmentsCompany = async (id: string, signal: AbortSignal) => {
      const response = await getEquipmentByCompany({ params: { id }, signal });
      if (response.error === 0) {
        setEquipmentsCompany(response.data);
      }
    };

    const getChipByCompanyData = async (id: string, signal: AbortSignal) => {
      const response = await getChipByCompany({
        params: { id },
        signal,
      });

      if (response.error === 0) {
        setChipByCompany(response.data);
      }
    };

    const getMaintenanceTypesData = async (signal: AbortSignal) => {
      const response = await getMaintenanceTypes({ signal });
      if (response.error === 0) {
        setTypeMaintenance(response.data);
      }
    };

    const controller = new AbortController();

    if (user) {
      getComponentByCompanyData(user?.cmpid, controller.signal);
      getEquipmentsCompany(user?.cmpid, controller.signal);
      getChipByCompanyData(user?.cmpid, controller.signal);
      getMaintenanceTypesData(controller.signal);
    }

    return () => {
      controller.abort();
    };
  }, [user]);

  const handleComponentSelect = ({
    target,
  }: ChangeEvent<HTMLSelectElement>) => {
    setComponent(target.value);
  };
  const handleEquipmentSelect = ({
    target,
  }: ChangeEvent<HTMLSelectElement>) => {
    setEquipment(target.value);
  };
  const handleTypeSelect = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    setType(target.value);
  };
  const handleChipSelect = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    setChip(target.value);
  };
  const handleStartDate = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setStartDate(target.value);
  };
  const handleEndDate = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setEndDate(target.value);
  };

  const getUrlParamsFromFilters = useCallback(() => {
    const params = [];

    if (component) {
      params.push({ type: 'component', value: component });
    }

    if (equipment) {
      params.push({ type: 'machine', value: equipment });
    }

    if (type) {
      params.push({ type: 'type', value: type });
    }

    if (chip) {
      params.push({ type: 'chip', value: chip });
    }

    if (startDate) {
      params.push({ type: 'startDate', value: startDate });
    }

    if (endDate) {
      params.push({ type: 'endDate', value: endDate });
    }

    if (linkMaintenanceId) {
      params.push({ type: 'id', value: linkMaintenanceId });
    }

    return params;
  }, [chip, component, equipment, type, startDate, endDate, linkMaintenanceId]);

  const handleFilterConfirm = useCallback(() => {
    const queryParams = getUrlParamsFromFilters();
    const encodedURI = encodeURIComponent(JSON.stringify(queryParams));

    setUrlQuery(encodedURI);
    setPage('fileType');
  }, [getUrlParamsFromFilters]);

  return (
    <Modal
      title="report-title"
      onClick={() => setShowReportMaintenance(false)}
      maxWidth={page === 'displayPdf' && rowData ? '900px' : '600px'}
    >
      <FormModal>
        {page === 'reportType' && (
          <>
            <Button onClick={() => setPage('filters')}>
              {t('report-select-maintenance')}
            </Button>
            <Button onClick={() => setPage('historyFileType')}>
              {t('report-select-history-component')}
            </Button>
            <Button onClick={() => setPage('assesmentFileType')}>
              {t('report-select-occurrence-evaluations')}
            </Button>
            <Button onClick={() => setPage('mtbfFileType')}>
              {t('report-select-mtbf')}
            </Button>
          </>
        )}
        {page === 'filters' && (
          <>
            <Dropdown
              label={t('company-tab-parts')}
              name="component"
              value={component}
              onChange={handleComponentSelect}
            >
              <option value="">{t('report-select-component')}</option>
              {componentsByCompany.map(option => (
                <option key={option.com_agr_code} value={option.com_agr_code}>
                  {option.com_description}
                </option>
              ))}
            </Dropdown>
            <Dropdown
              label={t('company-tab-equip')}
              name="equipment"
              value={equipment}
              onChange={handleEquipmentSelect}
            >
              <option value="">{t('report-select-equipment')}</option>
              {equipmentsCompany.map(option => (
                <option
                  key={option.mac_numeration}
                  value={option.mac_numeration}
                >
                  {option.mac_description}
                </option>
              ))}
            </Dropdown>
            <Dropdown
              label={t('maintenance-type-of-maintenance')}
              name="maintenanceType"
              value={type}
              onChange={handleTypeSelect}
            >
              <option value="">{t('report-select-type')}</option>
              {typeMaintenance.map(option => (
                <option key={option.mai_id} value={option.mai_id}>
                  {option.mai_description}
                </option>
              ))}
            </Dropdown>
            <Dropdown
              label="Chip"
              name="chip"
              value={chip}
              onChange={handleChipSelect}
            >
              <option value="">{t('report-select-chip')}</option>

              {chipByCompany.map(option => (
                <option
                  key={option.chi_serial_number}
                  value={option.chi_serial_number}
                >
                  {option.chi_serial_number}
                </option>
              ))}
            </Dropdown>

            <Box width="100%">
              <Box padding="0.3rem 0.75rem" fontSize="0.75rem">
                {t('period-select')}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Input
                  name="startDate"
                  type="date"
                  label=""
                  onChange={handleStartDate}
                  style={{
                    paddingTop: 0,
                  }}
                />
                <Input
                  name="endDate"
                  type="date"
                  label=""
                  onChange={handleEndDate}
                  style={{
                    paddingTop: 0,
                  }}
                />
              </Box>
            </Box>

            <FooterTwo style={{ marginTop: 20 }}>
              <ButtonTransparent
                aria-hidden="true"
                onClick={() => setPage('reportType')}
              >
                <FaArrowLeft color={colors.blue_light} />
                {t('generic-button-back')}
              </ButtonTransparent>
              <Button type="button" width="150px" onClick={handleFilterConfirm}>
                {t('company-parts-modal-next')}
              </Button>
            </FooterTwo>
          </>
        )}
        {page === 'fileType' && (
          <>
            <Content>
              <ContentExcel
                href={`${process.env.REACT_APP_API_URL}registerMaintenance/listReportExcel?filters=${urlQuery}&token=${storageToken}`}
              >
                <div>
                  <RiFileExcel2Fill size={80} />
                  {t('report-excel')}
                </div>
              </ContentExcel>
              <ContentPdf
                onClick={() => {
                  setRowData(
                    `${process.env.REACT_APP_API_URL}registerMaintenance/listReportPDF?filters=${urlQuery}&token=${storageToken}`,
                  );
                  setPage('displayPdf');
                }}
              >
                <div>
                  <PiFilePdfDuotone size={80} />
                  {t('report-pdf-download')}
                </div>
              </ContentPdf>
            </Content>
            <FooterTwo style={{ marginTop: 20 }}>
              <ButtonTransparent
                aria-hidden="true"
                onClick={() => setPage('filters')}
              >
                <FaArrowLeft color={colors.blue_light} />
                {t('generic-button-back')}
              </ButtonTransparent>
              <Button
                type="button"
                width="150px"
                onClick={() => setShowReportMaintenance(false)}
              >
                {t('generic-button-close')}
              </Button>
            </FooterTwo>
          </>
        )}
        {page === 'historyFileType' && (
          <>
            <Content>
              <ContentExcel
                href={`${process.env.REACT_APP_API_URL}registerMaintenance/historicOfComponentReportExcel?token=${storageToken}`}
              >
                <div>
                  <RiFileExcel2Fill size={80} />
                  {t('report-excel')}
                </div>
              </ContentExcel>
              <ContentPdf
                onClick={() => {
                  setRowData(
                    `${process.env.REACT_APP_API_URL}registerMaintenance/historicOfComponentReportPDF?token=${storageToken}`,
                  );
                  setPage('displayPdf');
                }}
              >
                <div>
                  <PiFilePdfDuotone size={80} />
                  {t('report-pdf-download')}
                </div>
              </ContentPdf>
            </Content>
            <FooterTwo style={{ marginTop: 20 }}>
              <ButtonTransparent
                aria-hidden="true"
                onClick={() => setPage('reportType')}
              >
                <FaArrowLeft color={colors.blue_light} />
                {t('generic-button-back')}
              </ButtonTransparent>
              <Button
                type="button"
                width="150px"
                onClick={() => setShowReportMaintenance(false)}
              >
                {t('generic-button-close')}
              </Button>
            </FooterTwo>
          </>
        )}
        {page === 'assesmentFileType' && (
          <>
            <Content>
              <ContentExcel
                href={`${process.env.REACT_APP_API_URL}registerMaintenance/reportAvaliaitonExcel?token=${storageToken}`}
              >
                <div>
                  <RiFileExcel2Fill size={80} />
                  {t('report-excel')}
                </div>
              </ContentExcel>
              <ContentPdf
                onClick={() => {
                  setRowData(
                    `${process.env.REACT_APP_API_URL}registerMaintenance/reportAvaliation?token=${storageToken}`,
                  );
                  setPage('displayPdf');
                }}
              >
                <div>
                  <PiFilePdfDuotone size={80} />
                  {t('report-pdf-download')}
                </div>
              </ContentPdf>
            </Content>
            <FooterTwo style={{ marginTop: 20 }}>
              <ButtonTransparent
                aria-hidden="true"
                onClick={() => setPage('reportType')}
              >
                <FaArrowLeft color={colors.blue_light} />
                {t('generic-button-back')}
              </ButtonTransparent>
              <Button
                type="button"
                width="150px"
                onClick={() => setShowReportMaintenance(false)}
              >
                {t('generic-button-close')}
              </Button>
            </FooterTwo>
          </>
        )}
        {page === 'mtbfFileType' && (
          <>
            <Content>
              <ContentExcel
                href={`${process.env.REACT_APP_API_URL}registerMaintenance/meanTimeBetweenFailures?token=${storageToken}`}
              >
                <div>
                  <RiFileExcel2Fill size={80} />
                  {t('report-excel')}
                </div>
              </ContentExcel>
              <ContentPdf
                onClick={() => {
                  setRowData(
                    `${process.env.REACT_APP_API_URL}registerMaintenance/meanTimeBetweenFailuresPdf?token=${storageToken}`,
                  );
                  setPage('displayPdf');
                }}
              >
                <div>
                  <PiFilePdfDuotone size={80} />
                  {t('report-pdf-download')}
                </div>
              </ContentPdf>
            </Content>
            <FooterTwo style={{ marginTop: 20 }}>
              <ButtonTransparent
                aria-hidden="true"
                onClick={() => setPage('reportType')}
              >
                <FaArrowLeft color={colors.blue_light} />
                {t('generic-button-back')}
              </ButtonTransparent>
              <Button
                type="button"
                width="150px"
                onClick={() => setShowReportMaintenance(false)}
              >
                {t('generic-button-close')}
              </Button>
            </FooterTwo>
          </>
        )}
        {page === 'displayPdf' && rowData && (
          <PDFDisplay url={rowData} toggleModal={setShowReportMaintenance} />
        )}
      </FormModal>
    </Modal>
  );
}
